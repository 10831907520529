import {graphql} from "gatsby"
import VideoCard from "../../../VideoCard"

// No middleware required for this component.
export default VideoCard

export const textComponent = graphql`
  fragment videoComponent on ContentfulVideo {
    contentfulId: contentful_id
    url
    title
    description {
      childMarkdownRemark {
        html
      }
    }
  }
`
