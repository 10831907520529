import React from "react"
import {graphql} from "gatsby"
import AllBuilders from "../../../AllBuilders"

const ContentfulBuildersListing = () => {
  return <AllBuilders />
}

export default ContentfulBuildersListing;

export const buildersListingComponent = graphql`
  fragment buildersListingComponent on ContentfulBuildersListing {
    contentfulId: contentful_id
    bottomMargin
  }
`
