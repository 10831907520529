import React from "react"
import { graphql } from "gatsby"
import LongtextMarkdown from "../../../LongtextMarkdown"
import CenterColumn from "../../../CenterColumn"

const TextComponent = ({ body }) => {
  return (
    <CenterColumn>
      <LongtextMarkdown value={body} />
    </CenterColumn>
  )
}

export default TextComponent

export const textComponent = graphql`
  fragment textComponent on ContentfulText {
    contentfulId: contentful_id
    body {
      childMarkdownRemark {
        html
      }
    }
  }
`
