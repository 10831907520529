import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BlogPostListItemFull from "../BlogPostListItemFull"

const LatestBlogs = () => {
  const { allContentfulBlogPost } = useStaticQuery(graphql`
    query {
      allContentfulBlogPost(sort: { fields: [publishDate], order: DESC }) {
        edges {
          node {
            title
            slug
            id
            publishDate
            description {
              childMarkdownRemark {
                html
              }
            }
            thumbnail {
              gatsbyImageData(layout: CONSTRAINED, width: 1000)
            }
          }
        }
      }
    }
  `)

  return (
    <>
      {allContentfulBlogPost.edges.map(blogPost => (
        <BlogPostListItemFull {...blogPost.node} key={blogPost.node.id} />
      ))}
    </>
  )
}

export default LatestBlogs
