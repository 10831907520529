import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import CardContent from "@mui/material/CardContent"
import Card from "@mui/material/Card"
import Link from "../Link"
import CardTitle from "../CardTitle"

export default class BuilderSmall extends React.Component {
  render() {
    const { builder, showName } = this.props
    return (
      <Card>
        {builder.picture && (
          <Link to={`/builder/${builder.slug}`}>
            <GatsbyImage
              image={builder.picture.gatsbyImageData}
              alt={builder.name}
            />
          </Link>
        )}
        {showName && (
          <CardContent>
            <CardTitle variant="h2">
              <Link to={`/builder/${builder.slug}`}>{builder.name}</Link>
            </CardTitle>
          </CardContent>
        )}
      </Card>
    )
  }
}
