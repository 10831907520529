import React from "react"
import { ButtonGroup } from "@mui/material"
import useMediaQuery from "@mui/material/useMediaQuery"
import { useTheme } from "@mui/material/styles"

const ResponsiveButtonSet = ({ children }) => {
  const theme = useTheme()
  const shouldStack = useMediaQuery(theme.breakpoints.down("md"))

  return (
    <ButtonGroup
      variant="contained"
      orientation={shouldStack ? "vertical" : "horizontal"}
      fullWidth={shouldStack}
    >
      {children}
    </ButtonGroup>
  )
}

export default ResponsiveButtonSet
