import React from "react"
import Typography from "@mui/material/Typography"
import { graphql } from "gatsby"
import CenterColumn from "../../../CenterColumn"

const HeadingComponent = ({ text, level, style }) => {
  const variant = `h${level}`

  return (
    <CenterColumn>
      <Typography variant={variant}>{text}</Typography>
    </CenterColumn>
  )
}

export default HeadingComponent

export const headingComponent = graphql`
  fragment headingComponent on ContentfulHeading {
    contentfulId: contentful_id
    text
    level
  }
`
