import React from "react"
import Link from "../Link"
import { GatsbyImage } from "gatsby-plugin-image"
import DateLine from "../DateLine"
import LongtextMarkdown from "../LongtextMarkdown"
import CardTitle from "../CardTitle"
import { FiftyFifty } from "../ReponsiveLayouts"

const BlogPostListItemFull = ({
  title,
  description,
  thumbnail,
  slug,
  publishDate,
}) => {
  const url = `/blog/${slug}`

  return (
    <FiftyFifty sx={{ backgroundColor: "white" }}>
      <Link to={url} flush>
        {thumbnail.gatsbyImageData && (
          <GatsbyImage image={thumbnail.gatsbyImageData} alt={title} />
        )}
      </Link>
      <>
        <DateLine date={publishDate} format="MMMM D, YYYY" />
        <CardTitle>
          <Link to={url}>{title}</Link>
        </CardTitle>
        <LongtextMarkdown value={description} />
      </>
    </FiftyFifty>
  )
}

export default BlogPostListItemFull
