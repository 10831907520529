import React from "react"
import { graphql } from "gatsby"
import AdvancedBuildsListing from "../../../AdvancedBuildsListing"
import WithBuildQuery from "../../../contexts/BuildQuery/WithBuildQuery"
import CenterColumn from "../../../CenterColumn"

const ContentfulBuildsListing = ({ useAjax, buildsPerPage }) => {
  return (
    <CenterColumn>
      <WithBuildQuery limit={buildsPerPage} ajax={useAjax}>
        <AdvancedBuildsListing />
      </WithBuildQuery>
    </CenterColumn>
  )
}

export default ContentfulBuildsListing

export const buildsListingComponent = graphql`
  fragment buildsListingComponent on ContentfulBuildsListing {
    contentfulId: contentful_id
    buildsPerPage
    bottomMargin
    useAjax
  }
`
