import React from "react"
import { graphql } from "gatsby"
import MediaCard from "../../../MediaCard"

const ContentfulMediaCard = ({ title, cardText, media, ctas }) => {
  // Renaming text because of field type collision.
  return <MediaCard {...{ title, media, ctas, text: cardText }} />
}

export default ContentfulMediaCard

export const mediaCardComponent = graphql`
  fragment mediaCardComponent on ContentfulMediaCard {
    contentfulId: contentful_id
    ctas {
      destination
      label
      contentfulId: contentful_id
    }
    media {
      title
      gatsbyImageData(layout: CONSTRAINED)
      file {
        url
      }
    }
    cardText: text {
      raw
    }
    title
  }
`
