import React from "react"
import { graphql } from "gatsby"
import Link from "../../../Link"
import { GatsbyImage } from "gatsby-plugin-image"
import LongtextMarkdown from "../../../LongtextMarkdown"
import Typography from "@mui/material/Typography"
import Button from "@mui/material/Button"
import { FiftyFifty } from "../../../ReponsiveLayouts"
import CenterColumn from "../../../CenterColumn"

const BuildCard = ({ build, title, description }) => {
  if (!build) {
    return null
  }
  const { primaryMedia, slug } = build
  const buildUrl = `/build/${slug}`

  return (
    <CenterColumn sx={{ my: 2 }}>
      <FiftyFifty>
        <Link to={buildUrl} flush>
          <GatsbyImage image={primaryMedia.gatsbyImageData} alt={title} />
        </Link>
        <div>
          <Typography variant="h3" align="left">
            <Link to={buildUrl}>{title ? title : build.title}</Link>
          </Typography>
          {description && <LongtextMarkdown value={description} />}
          <Button variant="contained" href={buildUrl}>
            See build
          </Button>
        </div>
      </FiftyFifty>
    </CenterColumn>
  )
}

export default BuildCard

export const buildCardFragment = graphql`
  fragment buildCardFragment on ContentfulBuildCard {
    contentfulId: contentful_id
    title
    description {
      childMarkdownRemark {
        html
      }
    }
    build {
      primaryMedia {
        gatsbyImageData(layout: CONSTRAINED, width: 1000)
      }
      title
      slug
    }
  }
`
