import React from "react"
import ReactPlayer from "react-player"
import LongtextMarkdown from "../LongtextMarkdown"
import CenterColumn from "../CenterColumn"
import Caption from "../Caption"
import { Box } from "@mui/system"
import styled from "@emotion/styled"

const VideoCard = ({ url, description }) => {
  const PlayerWrapper = styled(Box)(() => ({
    position: "relative",
    paddingTop: "56.25%" /* Player ratio: 100 / (1280 / 10) */,
  }))

  const Player = styled(ReactPlayer)(() => ({
    position: "absolute",
    top: 0,
    left: 0,
  }))

  return (
    <CenterColumn sx={{ my: 2 }}>
      <PlayerWrapper>
        <Player url={url} width="100%" height="100%" />
      </PlayerWrapper>

      {description && (
        <Caption>
          <LongtextMarkdown value={description} />
        </Caption>
      )}
    </CenterColumn>
  )
}

export default VideoCard
