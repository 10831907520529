import React from "react"
import styled from "@emotion/styled"
import { Box } from "@mui/system"

const Caption = ({ children }) => {
  const ImageCaptionWrapper = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2),
    backgroundColor: `#eee`,
    fontStyle: `italic`,
  }))

  return <ImageCaptionWrapper>{children}</ImageCaptionWrapper>
}

export default Caption
