import React, { useState } from "react"
import { StaticQuery, graphql } from "gatsby"
import Piecetype from "../Piecetype"
import Tabs from "@mui/material/Tabs"
import Tab from "@mui/material/Tab"
import { Box, Grid } from "@mui/material"

const Piecetypes = () => {
  const [activeIndex, setActiveIndex] = useState(0)

  return (
    <StaticQuery
      query={graphql`
        query {
          allContentfulPiecetypeSegment {
            edges {
              node {
                id: contentful_id
                pictureFull: picture {
                  gatsbyImageData(layout: CONSTRAINED, width: 1800)
                  file {
                    contentType
                    url
                  }
                }
                pictureThumbnail: picture {
                  gatsbyImageData(layout: CONSTRAINED, width: 450)
                }
                title
                combinedColors
                segmentType {
                  id: contentful_id
                  name
                }
                description {
                  childMarkdownRemark {
                    html
                  }
                }
              }
            }
          }
        }
      `}
      render={data => {
        // Divide piecetypes by category.
        let segmentsByType = {}
        data.allContentfulPiecetypeSegment.edges.forEach(edge => {
          const segment = edge.node
          if (segment.segmentType) {
            const segmentTypeId = segment.segmentType.id

            if (!segmentsByType[segmentTypeId]) {
              segmentsByType[segmentTypeId] = {
                ...segment.segmentType,
                segments: [],
              }
            }
            segmentsByType[segmentTypeId].segments.push(segment)
          }
        })

        const handleChange = (event, newIndex) => {
          setActiveIndex(newIndex)
        }

        return (
          <Box>
            <Box sx={{ my: 2 }}>
              <Tabs value={activeIndex} onChange={handleChange}>
                {Object.keys(segmentsByType).map(segmentTypeId => (
                  <Tab
                    label={segmentsByType[segmentTypeId].name}
                    key={segmentTypeId}
                  />
                ))}
              </Tabs>
            </Box>
            <Box>
              {Object.keys(segmentsByType).map((segmentTypeId, index) => (
                <>
                  {activeIndex === index && (
                    <Grid
                      key={`tabcontainer-${segmentTypeId}`}
                      container
                      spacing={2}
                    >
                      {segmentsByType[segmentTypeId].segments.map(segment => (
                        <Grid item xs={6} md={4}>
                          <Piecetype {...segment} key={segment.id} />
                        </Grid>
                      ))}
                    </Grid>
                  )}
                </>
              ))}
            </Box>
          </Box>
        )
      }}
    />
  )
}

export default Piecetypes
