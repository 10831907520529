import React from "react"
import { graphql } from "gatsby"
import LatestBlogs from "../../../LatestBlogs"
import CenterColumn from "../../../CenterColumn"

const ContentfulBlogsListing = () => {
  return (
    <CenterColumn sx={{ py: 3 }}>
      <LatestBlogs />
    </CenterColumn>
  )
}

export default ContentfulBlogsListing

export const blogsListingComponent = graphql`
  fragment blogsListingComponent on ContentfulBlogsListing {
    contentfulId: contentful_id
    bottomMargin
  }
`
