import React from "react"
import { graphql } from "gatsby"
import Piecetypes from "../../../Piecetypes"
import CenterColumn from "../../../CenterColumn"

const PiecetypeDirectoryComponent = ({ showLegend }) => {
  return (
    <CenterColumn>
      <Piecetypes />
    </CenterColumn>
  )
}

export default PiecetypeDirectoryComponent

export const piecetypeDirectoryComponent = graphql`
  fragment piecetypeDirectoryComponent on ContentfulPiecetypeDirectory {
    contentfulId: contentful_id
    showLegend
  }
`
