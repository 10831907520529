import React from "react"
import {graphql} from "gatsby"
import Divider from "@mui/material/Divider"

const DividerComponent = ({bottomMarginStyle}) => {
  return <Divider variant="middle" {...bottomMarginStyle} />
}

export default DividerComponent

export const dividerComponent = graphql`
  fragment dividerComponent on ContentfulDivider {
    contentfulId: contentful_id
    bottomMargin
  }
`
