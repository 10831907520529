import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import BuilderSmall from "../BuilderSmall"
import CenterColumn from "../CenterColumn"
import { Grid } from "@mui/material"

const AllBuilders = () => {
  const { allContentfulBuilder } = useStaticQuery(graphql`
    query {
      allContentfulBuilder {
        edges {
          node {
            name
            id
            slug
            picture {
              gatsbyImageData(layout: CONSTRAINED, width: 1000)
            }
          }
        }
      }
    }
  `)

  return (
    <CenterColumn>
      <Grid container spacing={2} sx={{ mb: 5 }}>
        {allContentfulBuilder.edges.map(builder => (
          <>
            {builder.node.picture && (
              <Grid item xs={3}>
                <BuilderSmall
                  key={`builder-${builder.node.id}`}
                  builder={builder.node}
                  showName={true}
                />
              </Grid>
            )}
          </>
        ))}
      </Grid>
    </CenterColumn>
  )
}

export default AllBuilders
