import React from "react"
import Box from "@mui/material/Box"

const ComposedContent = ({ components }) => {
  return (
    <Box>
      {components.map((data, i) => {
        try {
          const Component = require(`./pageComponents/${data.__typename}`)
            .default
          return (
            <Box key={`${data.contentfulId}--${i}`}>
              <Component {...data} />
            </Box>
          )
        } catch (e) {
          return console.log(`Unsupported component ${data.__typename}`)
        }
      })}
    </Box>
  )
}

export default ComposedContent
