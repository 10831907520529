import React from "react"
import { graphql } from "gatsby"
import StorageType from "../../../StorageType"
import CenterColumn from "../../../CenterColumn"

const StorageTypeCardComponent = ({ storageType }) => (
  <CenterColumn>
    <StorageType {...storageType} />
  </CenterColumn>
)

export default StorageTypeCardComponent

export const storageTypeCardComponent = graphql`
  fragment storageTypeCardComponent on ContentfulStorageTypeCard {
    contentfulId: contentful_id
    storageType {
      contentfulId: contentful_id
      productLink
      slug
      title
      description {
        childMarkdownRemark {
          html
        }
      }
      primaryMedia {
        gatsbyImageData(layout: CONSTRAINED, width: 1800)
        file {
          contentType
          url
        }
      }
      primaryMediaThumbnail: primaryMedia {
        gatsbyImageData(layout: CONSTRAINED, width: 800)
      }
    }
  }
`
