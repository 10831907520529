import React from "react"
import { graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import CenterColumn from "../../../CenterColumn"
import { Typography } from "@mui/material"
import Caption from "../../../Caption"

const ImageComponent = ({ image, caption }) => {
  return (
    <CenterColumn sx={{ my: 5 }}>
      <GatsbyImage image={image.gatsbyImageData} alt={image.title} />
      <Caption>
        <Typography variant="content">{caption}</Typography>
      </Caption>
    </CenterColumn>
  )
}

export default ImageComponent

export const imageComponent = graphql`
  fragment imageComponent on ContentfulImage {
    contentfulId: contentful_id
    image {
      title
      gatsbyImageData(layout: CONSTRAINED)
    }
    caption
  }
`
