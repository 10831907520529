import { Box } from "@mui/system"
import React from "react"
import { renderRichText } from "gatsby-source-contentful/rich-text"
import { GatsbyImage } from "gatsby-plugin-image"
import Button from "@mui/material/Button"
import ResponsiveButtonSet from "../ResponsiveButtonSet"
import CenterColumn from "../CenterColumn"
import { Typography } from "@mui/material"
import { FiftyFifty } from "../ReponsiveLayouts"

const MediaCard = ({ title, text, media, ctas }) => {
  return (
    <CenterColumn sx={{ my: 5 }}>
      <FiftyFifty>
        <GatsbyImage image={media.gatsbyImageData} alt={media.title} flush />
        <>
          <Typography variant="h2" align="left" sx={{ py: 0 }}>
            {title}
          </Typography>
          <Box>
            <Typography variant="content">{renderRichText(text)}</Typography>
          </Box>
          <ResponsiveButtonSet variant="contained">
            {ctas.map(cta => (
              <Button href={cta.destination} key={cta.contentfulId}>
                {cta.label}
              </Button>
            ))}
          </ResponsiveButtonSet>
        </>
      </FiftyFifty>
    </CenterColumn>
  )
}

export default MediaCard
