import React from "react"
import Card from "@mui/material/Card"
import CardContent from "@mui/material/CardContent"
import "react-image-lightbox/style.css"
import CardTitle from "../CardTitle"
import SingleImageLightbox from "../SingleImageLightbox"

const Piecetype = ({ pictureThumbnail, title, description, pictureFull }) => {
  return (
    <>
      <Card>
        <SingleImageLightbox
          thumbnailGatsbyImageData={pictureThumbnail.gatsbyImageData}
          fullImageUrl={pictureFull.file.url}
          caption={description.childMarkdownRemark.html}
        />
        <CardContent>
          <CardTitle>{title}</CardTitle>
        </CardContent>
      </Card>
    </>
  )
}

export default Piecetype
