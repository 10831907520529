import Lightbox from "react-image-lightbox"
import React, { useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"

import "react-image-lightbox/style.css"
import styled from "@emotion/styled"

const SingleImageLightbox = ({
  thumbnailGatsbyImageData,
  fullImageUrl,
  caption,
}) => {
  const [showFullImage, setShowFullImage] = useState(false)

  const closeFullImage = () => {
    setShowFullImage(false)
  }

  const ClickableImage = styled(GatsbyImage)(() => ({
    cursor: "pointer",
  }))

  return (
    <>
      <div onClick={() => setShowFullImage(true)}>
        <ClickableImage image={thumbnailGatsbyImageData} />
      </div>
      {showFullImage && (
        <Lightbox
          mainSrc={fullImageUrl}
          onCloseRequest={closeFullImage}
          imageCaption={
            <div
              dangerouslySetInnerHTML={{
                __html: caption,
              }}
            />
          }
        />
      )}
    </>
  )
}

export default SingleImageLightbox
