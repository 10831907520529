import React from "react"
import { graphql } from "gatsby"
import ReactCompareImage from "react-compare-image"
import { getSrc } from "gatsby-plugin-image"
import CenterColumn from "../../../CenterColumn"

const Scrubber = ({ imageOne, imageTwo }) => (
  <CenterColumn>
    <ReactCompareImage
      leftImage={getSrc(imageOne)}
      rightImage={getSrc(imageTwo)}
    />
  </CenterColumn>
)

export default Scrubber

export const scrubberComponent = graphql`
  fragment scrubberComponent on ContentfulScrubberCard {
    contentfulId: contentful_id
    bottomMargin
    imageOne {
      gatsbyImageData(layout: CONSTRAINED, width: 1000)
    }
    imageTwo {
      gatsbyImageData(layout: CONSTRAINED, width: 1000)
    }
  }
`
