import React from "react"
import Link from "../Link"
import CardTitle from "../CardTitle"
import SingleImageLightbox from "../SingleImageLightbox"
import { Button } from "@mui/material"
import LongtextMarkdown from "../LongtextMarkdown"
import { Box } from "@mui/system"
import { FiftyFifty } from "../ReponsiveLayouts"
import { Tab } from "@mui/icons-material"

const StorageType = ({
  productLink,
  description,
  primaryMedia,
  title,
  primaryMediaThumbnail,
  slug,
}) => {
  return (
    <FiftyFifty sx={{ my: 4 }}>
      <Box flush>
        <SingleImageLightbox
          fullImageUrl={primaryMedia.file.url}
          thumbnailGatsbyImageData={primaryMediaThumbnail.gatsbyImageData}
        />
      </Box>
      <Box>
        <CardTitle>
          <Link to={`/storage/${slug}`}>{title}</Link>
        </CardTitle>
        <LongtextMarkdown value={description} />
        <Button variant="contained" href={productLink} endIcon={<Tab />}>
          View Product
        </Button>
      </Box>
    </FiftyFifty>
  )
}

export default StorageType
